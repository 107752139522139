import { Component, Prop, Vue } from 'vue-property-decorator';
import { Doughnut } from 'vue-chartjs/legacy';
import {
  ChartData,
  ChartOptions,
  Chart as ChartJs,
  ArcElement,
  Tooltip
} from 'chart.js';
import { isMobile } from '@/helpers/app.helper';

ChartJs.defaults.font.size = 10;
ChartJs.register(
  ArcElement,
  Tooltip
);

@Component({
  name: 'PieChart',
  components: {
    Doughnut
  },
})
export default class PieChart extends Vue {
  @Prop({
    required: true
  })
  chartData!: ChartData;

  @Prop({
    required: false,
    default: () => ({})
  })
  options?: ChartOptions;

  @Prop({
    required: false,
    default: () => 'var(--table-tr-bg)'
  })
  backgroundColor!: string;

  @Prop({
    type: Number,
    required: false,
    default: () => 194
  })
  height?: number;

  @Prop({
    type: Number,
    required: false,
  })
  width?: number;

  defaultOptions: ChartOptions = {
    plugins: {
      legend: {
        align: 'start',
        position: isMobile() ? 'bottom' : 'right',
        labels: {
          usePointStyle: true,
          boxWidth: 9
        }
      },
    },
    layout: {
      padding: {
        top: 15,
      }
    },
    maintainAspectRatio: false,
  };

  get chartOptions() {
    return {
      ...this.defaultOptions,
      ...this.options
    }
  }

  get styles() {
    return {
      backgroundColor: this.backgroundColor,
      width: '100%',
    }
  }

  get canvasWidth() {
    return this.width;
  }

  get canvasHeight() {
    return this.height;
  }
}
