import { Component, Prop, Vue } from 'vue-property-decorator';
import PieChart from '@/components/ui/charts/pie-chart/pie-chart.vue';
import { ChartData, ChartOptions, TooltipItem } from 'chart.js';
import {
  COLOR_RED_20,
  COLOR_GREEN_20, COLOR_GREY_20,
} from '@/constants/color.constant';
import { isMobile } from '@/helpers/app.helper';
import { ProductViewType } from '@/types/api/reports/product-view.type';

@Component({
  name: 'SalesCostTotalChart',
  components: {
    PieChart,
  },
})
export default class SalesCostTotalChart extends Vue {

  @Prop({ required: true })
  data!: ProductViewType;

  get chartData(): ChartData {
    return {
      labels: this.labels,
      datasets: [
        {
          data: [this.ppcCost, this.ppcCost < this.ppcSales ? this.ppcSales - this.ppcCost : 0],
          backgroundColor: [this.colors[1], COLOR_GREY_20],
          weight: 0.5,
        },
        {
          data: [this.ppcSales, this.ppcCost >= this.ppcSales ? this.ppcCost - this.ppcSales : 0],
          backgroundColor: [this.colors[0], COLOR_GREY_20],
          weight: 1,
        },
      ]
    };
  }

  get ppcSales() {
    return this.data.total.total.ppcSales
  }

  get ppcCost() {
    return this.data.total.total.spend
  }

  get ppcSalesPercent() {
    return 100
  }

  get ppcCostPercent() {
    return this.ppcCost / this.ppcSales * 100
  }

  get items() {
    return [
      { name: 'ppcSales', value: this.ppcSales, percent: this.ppcSalesPercent },
      { name: 'ppcCost', value: this.ppcCost, percent: this.ppcCostPercent },
    ]
  }

  get chartOptions(): ChartOptions {
    return {
      responsive: !isMobile(),
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false,
          callbacks: {
            label: (tooltipItem: TooltipItem<'doughnut'>) => {
              return tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 1
                ? ''
                : `${tooltipItem.label}: ${tooltipItem.formattedValue}`
            },
          },
        },
      }
    }
  }

  getLabel(value: string) {
    return `${value.slice(0, 20)}${value.length > 20 ? '...' : ''}`;
  }

  getLabelValue(idx: number) {
    return this.items[idx].value;
  }

  getLabelPercent(idx: number) {
    const val = this.items[idx].percent;
    return val.toFixed(1) + '%';
  }

  get labels(): string[] {
    return [
      this.$t('productViewReport.ppcSalesChart') as string,
      this.$t('productViewReport.ppcCostChart') as string,
    ]
  }

  get colors() {
    return [
      COLOR_GREEN_20,
      COLOR_RED_20,
    ]
  }

  getColorStyle(idx: number) {
    return {
      backgroundColor: this.colors[idx]
    }
  }
}
