import { Component, Mixins } from "vue-property-decorator";
import Layout from "@/components/layouts/main.vue";
import PageTitle from "@/components/ui/page-title/pageTitle.vue";
import { Action, Getter, Mutation } from "vuex-class";
import FilterMixin from "@/mixins/filter.mixin";
import BoxHeader from "@/components/ui/box-header/boxHeader.vue";
import { ReportsProductViewRequest } from "@/types/request/reports-request.types";
import { DateTime, Info } from "luxon";
import {
  DATE_MODE_CUSTOM_WEEKLY,
  DATEPICKER_FORMAT,
  yearsInterval,
  DATE_MODE_MONTHLY,
  getDateTitle,
  DATE_FORMAT,
  DATE_MODE_DAILY,
} from "@/helpers/date.helper";
import { ProductsGetRequest } from "@/types/request/products-request.types";
import { ProductType } from "@/types/api/products/product.type";
import DatepickerRangeMode from "@/components/ui/inputs/datepicker-range-mode/datepicker-range-mode.vue";
import { BFormRadioGroup } from "bootstrap-vue";
import { ProductViewType } from "@/types/api/reports/product-view.type";
import PpcMetricsColumns from "@/components/pages/reports/ppc-metrics-report/ppc-metrics-columns.vue";
import ExportXlsxBtn from "@/components/ui/buttons/export-xlsx-btn/export-xlsx-btn.vue";
import { exportToXls, getXlsFilename } from "@/helpers/xlsx.helper";
import AutocompleteInput from "@/components/ui/inputs/autocomplete-input/autocompleteInput.vue";
import ProductDropdownInput from "@/components/ui/inputs/product-dropdown-input/product-dropdown-input.vue";
import ModeSwitcher from "@/components/ui/mode-switcher/mode-switcher.vue";
import ChartsBox from "@/components/ui/charts-box/charts-box.vue";
import ChartsPicker from "@/components/ui/charts-picker/charts-picker.vue";
import SalesCostTotalChart from "@/components/ui/charts/sales-cost-total-chart/sales-cost-total-chart.vue";
import ClicksOrdersTotalChart from "@/components/ui/charts/clicks-orders-total-chart/clicks-orders-total-chart.vue";
import ProductBox from "@/components/ui/product-box/product-box.vue";
import XlsExportMixin from "@/mixins/xls-export.mixin";

const title = "productViewReport.title";

@Component({
  name: "PpcMetricsReport",
  page: {
    title,
  },
  components: {
    Layout,
    PageTitle,
    BoxHeader,
    DatepickerRangeMode,
    BFormRadioGroup,
    PpcMetricsColumns,
    ExportXlsxBtn,
    AutocompleteInput,
    ProductDropdownInput,
    ModeSwitcher,
    ChartsBox,
    ChartsPicker,
    SalesCostTotalChart,
    ClicksOrdersTotalChart,
    ProductBox,
  },
})
export default class PpcMetricsReport extends Mixins(
  FilterMixin,
  XlsExportMixin,
) {
  title = title;
  filter: any = this.initFilter();
  saveFilter = true;
  pageFilter: any = {
    brandId: this.$route.query.brandId || null,
  };
  useBrandFilter = true;

  @Action("getProductViewReport", { namespace: "reports" })
  getProductViewReport!: (
    params?: ReportsProductViewRequest,
  ) => Promise<ProductViewType>;

  @Getter("getProductViewReport", { namespace: "reports" })
  productViewReport!: ProductViewType;

  @Mutation("SET_PRODUCT_VIEW_REPORT", { namespace: "reports" })
  setProductViewReport!: (params: ProductViewType | null) => any;

  @Action("getProducts", { namespace: "products" })
  getProducts!: (params?: ProductsGetRequest) => Promise<ProductType[]>;

  @Getter("getProducts", { namespace: "products" })
  products!: ProductType[];

  created() {
    this.filter.from = this.$route.query.from || this.startMonthDate;
    this.filter.to = this.$route.query.to || this.endMonthDate;
    this.pageFilter.type = this.$route.query.type || DATE_MODE_CUSTOM_WEEKLY;

    if (this.pageFilter.brandId) {
      this.getProductsRequest(this.pageFilter.brandId);
    }
    if (this.filter.from && this.filter.to && this.filter.productId) {
      this.getProductViewReportRequest();
    }
  }

  beforeDestroy() {
    this.setProductViewReport(null);
  }

  getProductViewReportRequest() {
    return this.getProductViewReport(this.preparedFilter);
  }

  getProductsRequest(value) {
    this.getProducts({ brandId: value });
  }

  get preparedFilter() {
    return {
      ...this.filter,
      from: DateTime.fromFormat(this.filter.from, DATEPICKER_FORMAT).toFormat(
        DATE_FORMAT,
      ),
      to: DateTime.fromFormat(this.filter.to, DATEPICKER_FORMAT).toFormat(
        DATE_FORMAT,
      ),
    };
  }

  get startMonthDate() {
    return DateTime.local()
      .setZone("utc")
      .startOf("month")
      .toFormat(DATEPICKER_FORMAT);
  }

  get endMonthDate() {
    return DateTime.local().endOf("month").toFormat(DATEPICKER_FORMAT);
  }

  initFilter() {
    return {
      from: this.$route.query.from || null,
      to: this.$route.query.to || null,
      productId: this.$route.query.productId || null,
      type: this.$route.query.type || null,
    };
  }

  get monthsOptions() {
    return [
      { text: this.$t("productViewReport.month"), value: null },
      ...Info.months("long").map((item) => ({ text: item, value: item })),
    ];
  }

  get yearsOptions() {
    return [
      { text: this.$t("productViewReport.year"), value: null },
      ...yearsInterval(
        DateTime.local().minus({ years: 2 }),
        DateTime.local(),
      ).map((item) => ({ text: item, value: item })),
    ];
  }

  get statistics(): any {
    return this.productViewReport
      ? [
          ...Object.values(this.productViewReport.statistics),
          {
            ...this.productViewReport.total,
            from: "total",
          },
        ]
      : [];
  }

  get modeOptions() {
    return [
      { text: this.$t("dateMode.daily"), value: DATE_MODE_DAILY },
      { text: this.$t("dateMode.weekly"), value: DATE_MODE_CUSTOM_WEEKLY },
      { text: this.$t("dateMode.monthly"), value: DATE_MODE_MONTHLY },
    ];
  }

  get brandOptions() {
    return [
      { text: "---", value: null },
      ...this.brands.map((item) => ({
        text: item.displayName,
        value: item.id,
      })),
    ];
  }

  onSelectBrand(value) {
    this.getProductsRequest(value);
  }

  getDateTitle({ from, to }) {
    return getDateTitle(from, to, this.pageFilter.type);
  }

  getPeriodName(index: number) {
    return this.pageFilter.type === DATE_MODE_MONTHLY
      ? ""
      : `(${this.$t("common.week")} ${index + 1})`;
  }

  onExportV2() {
    if (this.productViewReport) {
      const fileName = getXlsFilename(this.$t(title) as string, this.filter);
      exportToXls(fileName, this.$refs.exportTable, { isTable: true });
    }
  }

  onExport() {
    if (this.productViewReport) {
      const data: any = [];
      const fileName = getXlsFilename(this.$t(title) as string, this.filter);
      Object.values(this.productViewReport.statistics).forEach((statValues) => {
        const product = this.products.find(
          (item) => item.id === Number(this.filter.productId),
        );
        const commonData = {
          [this.$t("common.period") as string]: getDateTitle(
            statValues.from,
            statValues.to,
            this.filter.type,
          ),
          [this.$t("productViewReport.brand") as string]:
            this.brands.find(
              (item) => item.id === Number(this.pageFilter.brandId),
            )?.displayName || "",
          [this.$t("productViewReport.product") as string]:
            product?.displayName || product?.name || "",
          [this.$t("productViewReport.asin") as string]: product?.asin || "",
        };
        data.push({
          ...commonData,
          ...this.getValuesByCampaignType("productKeywordCampagin", statValues),
        });
        data.push({
          ...commonData,
          ...this.getValuesByCampaignType(
            "productCategoryCampagin",
            statValues,
          ),
        });
        data.push({
          ...commonData,
          ...this.getValuesByCampaignType("productAsinCampagin", statValues),
        });
        data.push({
          ...commonData,
          ...this.getValuesByCampaignType("productAutoCampagin", statValues),
        });
        data.push({
          ...commonData,
          ...this.getValuesByCampaignType("displayCampagin", statValues),
        });
        data.push({
          ...commonData,
          ...this.getValuesByCampaignType("brandCampagin", statValues),
        });
        data.push({
          ...commonData,
          ...this.getValuesByCampaignType("brandVideoCampagin", statValues),
        });
        data.push({
          ...commonData,
          ...this.getValuesByCampaignType("total", statValues),
        });
      });
      exportToXls(fileName, data);
    }
  }

  getValuesByCampaignType(type: string, stats: any) {
    return {
      [this.$t("productViewReport.campaignType") as string]: this.$t(
        `productViewReport.${type}`,
      ),
      [this.$t("productViewReport.impressions") as string]:
        this.numberFormatter(stats[type].impressions),
      [this.$t("productViewReport.clicks") as string]: this.numberFormatter(
        stats[type].clicks,
      ),
      CTR: this.numberFormatter(
        (stats[type].clicks / stats[type].impressions) * 100,
        1,
      ),
      [this.$t("productViewReport.unitPPC") as string]: this.numberFormatter(
        stats[type].ppcUnits,
      ),
      [this.$t(
        "productViewReport.ppcSales",
      ) as string]: `$${this.numberFormatter(stats[type].ppcSales, 1)}`,
      [this.$t("productViewReport.ppcOrders") as string]: this.numberFormatter(
        stats[type].ppcOrders,
      ),
      [this.$t("productViewReport.spend") as string]: `$${this.numberFormatter(
        stats[type].spend,
        1,
      )}`,
      [this.$t("productViewReport.acos") as string]: `${this.numberFormatter(
        stats[type].acos * 100,
        1,
      )}%`,
      [this.$t(
        "productViewReport.conversation",
      ) as string]: `${this.numberFormatter(stats[type].conversion, 1)}%`,
    };
  }

  get selectedProduct() {
    return this.products.find(
      (item) => item.id === Number(this.filter.productId),
    );
  }
}
