<template>
  <tr :class="{ total: name === 'total' }">
    <td class="td-break">{{ title }}</td>
    <template v-for="productItem of statistics">
      <td :key="`${productItem.from}-${name}-impressions`">
        <div class="td-row">{{ formatNumber(productItem[name].impressions) }}</div>
      </td>
      <td :key="`${productItem.from}-${name}-clicks`">
        <div class="td-row">
          <div class="td-row-left">{{ formatNumber(productItem[name].clicks) }}</div>
          <div
            v-if="!onlyValue"
            class="td-row-right"
            v-b-tooltip.hover
            :title="`CTR - ${formatNumber(productItem[name].clicks / productItem[name].impressions * 100, 1)}%`"
          >
            {{ formatNumber(productItem[name].clicks / productItem[name].impressions * 100) }}%
          </div>
        </div>
      </td>
      <td :key="`${productItem.from}-${name}-ppcOrders`" class="td-break">
        <div class="td-row">
          <div class="td-row-left">{{ formatNumber(productItem[name].ppcOrders) }}</div>
          <div
            v-if="!onlyValue"
            class="td-row-right"
            v-b-tooltip.hover
            :title="`${$t('productViewReport.conversation')} - ${formatNumber(productItem[name].conversion, 1)}%`"
          >
            {{ formatNumber(productItem[name].conversion) }}%
          </div>
        </div>
      </td>
      <td :key="`${productItem.from}-${name}-unitPPC`">
        <div class="td-row">{{ formatNumber(productItem[name].ppcUnits) }}</div>
      </td>
      <td :key="`${productItem.from}-${name}-ppcSales`">
        <div class="td-row">${{ formatNumber(productItem[name].ppcSales, 1) }}</div>
      </td>
      <td :key="`${productItem.from}-${name}-ppcCost`" class="td-break">
        <div class="td-row">
          <div class="td-row-left">${{ formatNumber(productItem[name].spend, 1) }}</div>
          <div
            v-if="!onlyValue"
            class="td-row-right"
            v-b-tooltip.hover
            :title="`${$t('productViewReport.acos')} - ${formatNumber(productItem[name].acos * 100, 1)}%`"
          >
            {{ formatNumber(productItem[name].acos * 100) }}%
          </div>
        </div>
      </td>
    </template>
  </tr>
</template>

<script type="tsx">
import { numberFormatter } from '@/helpers/string.helper';
import { VBTooltip } from 'bootstrap-vue';

export default {
  name: 'PpcMetricsColumns',
  props: ['name', 'title', 'statistics', 'onlyValue'],
  methods: {
    formatNumber(value, parts = 0) {
      return numberFormatter(value, { fractionPartCount: parts });
    }
  },
  directives: {
    'b-tooltip': VBTooltip
  },
};
</script>
<style scoped lang="scss"></style>
