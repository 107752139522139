import { render, staticRenderFns } from "./sales-cost-total-chart.vue?vue&type=template&id=3488e106&scoped=true&v-if=data&"
import script from "./sales-cost-total-chart.ts?vue&type=script&lang=ts&"
export * from "./sales-cost-total-chart.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3488e106",
  null
  
)

export default component.exports