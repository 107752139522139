import { Component, Mixins } from "vue-property-decorator";
import { ListResponseType } from "@/types/response/list-response.type";
import { appConfig } from "@/app.config";
import { exportToXls, getXlsFilename } from "@/helpers/xlsx.helper";
import { PaginationType } from "@/types/request/pagination.type";
import FilterMixin from "@/mixins/filter.mixin";
import { IGridTableSort } from "@/components/ui/grid-table/gridTable";
import { numberFormatter } from "@/helpers/string.helper";
import { BookType } from "xlsx";

export interface IXlsDataExportOptions {
  title: string;
  content: Array<{
    label: string | any;
    getValue: (item: any) => any;
  }>;
  bookType?: BookType;
}

export type IXlsExportOptions = IXlsDataExportOptions & {
  action: (params: PaginationType) => Promise<ListResponseType<any>>;
  sort?: IGridTableSort;
  bookType?: BookType;
};

@Component
export default class XlsExportMixin extends Mixins(FilterMixin) {
  tryingToExport = false;

  /**
   * Use if data with pagination
   */
  exportData?: ListResponseType<any>;

  /**
   * Use if data without pagination
   */
  get arrExportData(): any[] {
    return [];
  }

  get exportCount() {
    return this.exportData ? this.exportData.total : this.arrExportData.length;
  }

  /**
   * Use if data with pagination
   */
  xlsExport(options: IXlsExportOptions) {
    const { action, sort, bookType = "xlsx" } = options;
    if (this.exportCount && !this.tryingToExport) {
      this.tryingToExport = true;
      action({
        skip: 0,
        take: appConfig.xlsExportItemsLimit,
        ...this.prepareFilter(),
        ...this.sortFilter(sort),
      })
        .then((response) => {
          if (response && response.total) {
            this.exportContent(response.result, {
              ...options,
              bookType,
            });
          }

          this.tryingToExport = false;
        })
        .catch(() => (this.tryingToExport = false));
    }
  }

  /**
   * Use if data without pagination
   */
  xlsDataExport(data: any, options: IXlsDataExportOptions) {
    if (data.length && !this.tryingToExport) {
      this.tryingToExport = true;
      this.exportContent(data, {
        ...options,
        bookType: "csv",
      });
      this.tryingToExport = false;
    }
  }

  numberFormatter(value: number, parts = 0) {
    return numberFormatter(value, {
      thousandsChar: "",
      fractionPartCount: parts,
    });
  }

  private exportContent(data: any, options: IXlsDataExportOptions) {
    const { title, content } = options;
    const result: any = [];
    const fileName = getXlsFilename(this.$t(title) as string, {
      ...this.filter,
      ...this.pageFilter,
    });

    data.forEach((item) => {
      const row = {};
      content.forEach((cItm) => (row[cItm.label] = cItm.getValue(item)));
      result.push(row);
    });

    exportToXls(fileName, result);
  }
}
